import React from 'react'
import {ImCross} from "react-icons/im"

const ToDos = ({toDo, removeTodo, id}) => {
  return (
    <div className='t-container'>
        <span className='cross' onClick={()=>{removeTodo(id)}}><ImCross size={15}/></span>
        <span>{toDo}</span>
    </div>
  )
}

export default ToDos